import React from 'react';
import {
  Checkbox,
  List,
  ListItem,
  Collapse,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDmaHierarchy, useStakeholderTopics } from 'src/Esg/Dmav2/hooks';
import { useTranslation } from 'react-i18next';

interface HierarchySelectorProps {
  esg_stakeholder_data_id: number;
}

function SubSubTopic({
  esg_stakeholder_data_id,
  sub_sub_topic_id,
}: {
  esg_stakeholder_data_id: number;
  sub_sub_topic_id: number;
}) {
  const { subSubTopics, updateSubSubTopic } = useStakeholderTopics(
    esg_stakeholder_data_id
  );
  const ss = subSubTopics.filter((ss) => ss.id === sub_sub_topic_id)[0]; // TODO? return maps from hooks and use them here
  const { t } = useTranslation();

  return (
    <>
      <Checkbox
        checked={ss.is_selected}
        onChange={(e) =>
          updateSubSubTopic.mutate({
            sub_sub_topic_id: ss.id,
            is_selected: e.target.checked,
          })
        }
      />
      <Typography variant="body2">
        {ss.description ||
          t('esg:dma.selectingStakeholders.unnamedSubSubTopic')}
      </Typography>
    </>
  );
}

function SubTopic({
  esg_stakeholder_data_id,
  sub_topic_id,
}: {
  esg_stakeholder_data_id: number;
  sub_topic_id: number;
}) {
  const { subTopics, subSubTopics, updateSubTopic } = useStakeholderTopics(
    esg_stakeholder_data_id
  );
  const subTopic = subTopics.filter((sub) => sub.id === sub_topic_id)[0]; // TODO? return maps from hooks and use them here
  const subsubtopics = subSubTopics.filter(
    (ss) => ss.subtopic_id === sub_topic_id
  );
  const [open, setOpen] = React.useState(false);

  const hasSubSubTopics = subsubtopics?.length > 0;
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box width="36px" display="flex">
          {hasSubSubTopics && (
            <IconButton size="small" onClick={() => setOpen((prev) => !prev)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </Box>
        <Checkbox
          checked={subTopic.is_selected}
          onChange={(e) =>
            updateSubTopic.mutate({
              sub_topic_id: subTopic.id,
              is_selected: e.target.checked,
            })
          }
        />
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          {subTopic.description}
        </Typography>
      </Box>
      <Collapse in={open}>
        <List disablePadding>
          {subsubtopics.map((ss) => (
            <ListItem
              key={ss.id}
              sx={{ pl: 6, borderLeft: '1px solid gray', ml: '16px' }}
            >
              <SubSubTopic
                esg_stakeholder_data_id={esg_stakeholder_data_id}
                sub_sub_topic_id={ss.id}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

function Topic({
  esg_stakeholder_data_id,
  topic_id,
}: {
  esg_stakeholder_data_id: number;
  topic_id: number;
}) {
  const { topics } = useDmaHierarchy({});
  const { subTopics } = useStakeholderTopics(esg_stakeholder_data_id);
  const topic = topics.filter((t) => t.id === topic_id)[0];
  const subtopics = subTopics.filter((sub) => sub.topic_id === topic_id);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton size="small" onClick={() => setOpen((prev) => !prev)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <Typography variant="h4">{topic.name}</Typography>
      </Box>
      <Collapse in={open}>
        <List disablePadding>
          {subtopics.map((subtopic) => (
            <ListItem
              key={subtopic.id}
              sx={{
                pl: '4px',
                display: 'block',
                borderLeft: '1px solid gray',
                marginLeft: '16px',
              }}
            >
              <SubTopic
                esg_stakeholder_data_id={esg_stakeholder_data_id}
                sub_topic_id={subtopic.id}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default function HierarchySelector({
  esg_stakeholder_data_id,
}: HierarchySelectorProps) {
  const { topics } = useDmaHierarchy({});

  return (
    <List>
      {topics.map((topic) => (
        <Topic
          key={topic.id}
          esg_stakeholder_data_id={esg_stakeholder_data_id}
          topic_id={topic.id}
        />
      ))}
    </List>
  );
}
