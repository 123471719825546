import React from 'react';
import { Box } from '@mui/material';

import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { useQuestionnaires, useQv3Context } from '../hooks';
import Title from './Title';
import Description from './Description';
import Questions from '../Questions';

export default function Questionnaire() {
  const { questionnaireId } = useQv3Context();
  const { _instance: questionnaire, isLoading } = useQuestionnaires(
    { id: questionnaireId },
    { enabled: !!questionnaireId }
  );

  // redndering guards
  if (!questionnaireId) return null;
  if (isLoading) return <PageLoading />;
  if (questionnaire === undefined) return <SomethingWentWrong />;

  const { title, description } = questionnaire;

  return (
    <Box>
      <Title>{title}</Title>

      {/* This Box handles margins (for left-margin-straigh-line and right margin actions (comments, references, etc.)) */}
      <Box m="32px" p="0px 48px" borderLeft="2px solid #bbb">
        <Description description={description} />
        <br />
        <Questions />
      </Box>
    </Box>
  );
}
