import React from 'react';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { Comment as CommentIcon } from '@mui/icons-material';

import ConfirmationModal from 'src/components/ConfirmationModal';
import { useTranslation } from 'react-i18next';

import { EsgStakeholderAnswerCommentsOutSchema } from 'src/Esg/Dmav2/types';
import SingleComment from './SingleComment';

interface CommentsPopupProps {
  comments: EsgStakeholderAnswerCommentsOutSchema[];
  onCreate?: (text: string) => Promise<void> | void;
  onUpdate?: (
    updated: EsgStakeholderAnswerCommentsOutSchema
  ) => Promise<void> | void;
  onDelete?: (id: number) => Promise<void> | void;
  label?: string;
  badgeColor?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  readOnly?: boolean;
}

export default function CommentsPopup(props: CommentsPopupProps) {
  const {
    comments,
    onCreate,
    onUpdate,
    onDelete,
    label = 'comments',
    badgeColor = 'primary',
    readOnly = false,
  } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'questionnaireV3.comments',
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [newText, setNewText] = React.useState('');

  const [deleteId, setDeleteId] = React.useState<number | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNewText('');
  };

  const handleCreateComment = async () => {
    if (!onCreate) return;
    const text = newText.trim();
    if (!text) return;
    await onCreate(text);
    setNewText('');
  };

  const confirmDelete = async () => {
    if (deleteId == null || !onDelete) return;
    await onDelete(deleteId);
    setDeleteId(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Badge badgeContent={comments.length} color={badgeColor}>
          <CommentIcon />
        </Badge>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box width="600px" p="20px">
          <Typography variant="h4">{t(`${label}`)}</Typography>
          <Box height="10px" />

          {comments.length === 0 && (
            <Typography variant="caption">{t('noComments')}.</Typography>
          )}

          <Box maxHeight="70vh" overflow="auto">
            {comments.map((comment) => (
              <SingleComment
                key={comment.id}
                comment={comment}
                onUpdate={!readOnly ? onUpdate : undefined}
                onDelete={
                  !readOnly ? (id: number) => setDeleteId(id) : undefined
                }
                t={t}
              />
            ))}
          </Box>

          {!readOnly && (
            <>
              <Divider sx={{ m: '20px 0px' }} />
              <TextField
                size="small"
                placeholder={t('startTyping')}
                label={t('addComment')}
                fullWidth
                autoFocus
                multiline
                value={newText}
                onChange={(e) => setNewText(e.target.value)}
              />
              <Button
                disabled={!newText.trim()}
                size="small"
                variant="contained"
                onClick={handleCreateComment}
                sx={{ float: 'right', m: 2, mr: 0 }}
              >
                {t('save')}
              </Button>
            </>
          )}

          {!readOnly && open && (
            <ConfirmationModal
              titleKey="common.areYouSure"
              trueButtonTextKey="common.yes"
              falseButtonTextKey="common.no"
              open={!!deleteId}
              onClose={() => setDeleteId(null)}
              onFalse={() => setDeleteId(null)}
              onTrue={confirmDelete}
            />
          )}
        </Box>
      </Popover>
    </>
  );
}
