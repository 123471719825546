import { KeyboardArrowLeft } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledFlex } from 'src/components/StyledFlex';

interface Props {
  children: React.ReactNode;
}

export default function Title(props: Readonly<Props>) {
  const navigate = useNavigate();
  return (
    <StyledFlex>
      <IconButton onClick={() => navigate(-1)}>
        {/* This Typography ensures proper color text styling */}
        <Typography>
          <KeyboardArrowLeft sx={{ fontSize: '48px' }} />
        </Typography>
      </IconButton>
      <Typography variant="h1">{props.children}</Typography>
    </StyledFlex>
  );
}
