import React from 'react';
import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useTranslation } from 'react-i18next';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en-US';
import { toast } from 'react-toastify';

import { RequiredStateError } from './exceptions';
import { Country, IsoCountry } from './types';
import {
  client,
  NinjaValidationData,
  NinjaValidationError,
} from 'src/utils/api-client';
import { defaultElementsPerPageOptions } from 'src/components/Pagination';
import { useAppSelector, usePlatform } from '../redux-file/hooks';
import { RootState } from '../redux-file/store';
import {
  OrganizationDataOutSchema,
  ProductPlatform,
} from '../utils/api.interfaces';
import { UUID } from 'crypto';
import { UploadedFileSchema } from 'src/Ghg/CodeOfConductSurvey/types';
import axios, { AxiosError } from 'axios';

export const useRequiredSelector = <TProp>(
  // eslint-disable-next-line no-unused-vars
  selector: (state: RootState) => TProp | undefined
) =>
  useAppSelector((state) => {
    const result = selector(state);
    if (result === undefined) {
      throw new RequiredStateError();
    }
    return result;
  });

function assertPropertyRequired<T, K extends keyof T>(
  obj: T,
  key: K
): asserts obj is T & Record<K, NonNullable<T[K]>> {
  if (obj[key] === undefined || obj[key] === null) {
    throw new RequiredStateError();
  }
}

export const useOrganization = () => {
  const { activeOrganization } = usePlatform();
  if (activeOrganization === null) throw new RequiredStateError();
  return activeOrganization;
};

export const useProductOrganization = <T extends ProductPlatform>(
  product: T
): OrganizationDataOutSchema &
  Record<T, NonNullable<OrganizationDataOutSchema[T]>> => {
  const organization = useOrganization();
  assertPropertyRequired(organization, product);
  return organization;
};

export const useGhgOrganization = () =>
  useProductOrganization(ProductPlatform.Co2);
export const useEsgOrganization = () =>
  useProductOrganization(ProductPlatform.Esg);
export const useLcaOrganization = () =>
  useProductOrganization(ProductPlatform.Lca);
export const useCbamOrganization = () =>
  useProductOrganization(ProductPlatform.Cbam);

const getLocale = (language: string) => {
  switch (language) {
    case 'pl':
      return pl;
    case 'en':
      return en;
    default:
      return en;
  }
};

export type UseLanguageOptions = {
  keyPrefix?: string;
};

export const useLanguage = (options?: UseLanguageOptions) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: options?.keyPrefix,
  });
  const locale = getLocale(i18n.language);
  return {
    t,
    i18n,
    language: i18n.language,
    setLanguage: i18n.changeLanguage,
    locale,
  };
};

export const useContactUsPrompt = () => {
  return {
    navigateMailTo: () => window.open('mailto:contact@envirly.com', '_blank'),
  };
};

export const useSupportedCountries = () => {
  const { language } = useLanguage();
  const query = useQuery<Country[]>(['supportedCountries', { language }], () =>
    client
      .get('web/countries/supported-countries')
      .then((res) => res?.data || [])
  );
  return { ...query, data: query.data || [] };
};

export const useAllCountries = () => {
  const { language } = useLanguage();
  const query = useQuery<IsoCountry[]>(['allCountries', { language }], () =>
    client.get('web/countries/all').then((res) => res?.data || [])
  );
  return { ...query, data: query.data || [] };
};

export const useEUCountries = () => {
  const { language } = useLanguage();
  const query = useQuery<IsoCountry[]>(['EuCountries', { language }], () =>
    client.get('web/countries/eu').then((res) => res?.data || [])
  );
  return { ...query, data: query.data || [] };
};

export const useNonEUCountries = () => {
  const { language } = useLanguage();
  const query = useQuery<IsoCountry[]>(['NonEuCountries', { language }], () =>
    client.get('web/countries/non-eu').then((res) => res?.data || [])
  );
  return { ...query, data: query.data || [] };
};

export type QueryOptions<OutSchema> = Omit<
  UseQueryOptions<OutSchema, NinjaValidationError, OutSchema, QueryKey>,
  'queryKey' | 'queryFn'
>;

const mergeErrors = (...errors: any[]) =>
  errors.find((err) => err && Object.keys(err).length > 0) || {};

export const useResourceController = <
  InSchema,
  OutSchema extends { id: number | UUID },
>(
  url: string,
  queryKey: any[],
  // when params has { id } it is considered as single resource request
  params: { [key: string]: any } = {},
  queryOptions: QueryOptions<any> & { invalidateKey?: any } = {
    // IMPORTANT: by default query will be disabled if any of the params is null or undefined
    // eg { id: null } or {name: undefined} etc.
    // Since we are using hooks we want to disable the query until all required params are provided
    // otherwise the request will be sent twice: firstly for all request.user data, then for the queryParam data
    enabled: !Object.values(params).some(
      (value) => value === null || value === undefined
    ),
  },
  allowedMethods: string[] = ['GET', 'POST', 'PUT', 'DELETE']
) => {
  const queryClient = useQueryClient();

  // when params has { id } it is considered as single resource request
  const isSingleResourceRequest = params.id !== undefined;

  // disable query if any of the params is null/undefined or if it's detailed request { id }
  const getQueryOptions = (isSingleResourceQuery: boolean = false) => ({
    ...queryOptions,
    enabled:
      allowedMethods.includes('GET') &&
      queryOptions.enabled &&
      isSingleResourceQuery === isSingleResourceRequest,
  });

  const onSuccess = () => {
    // TODO: consider something more fancy
    toast.success('Success');
    queryClient.invalidateQueries(queryOptions.invalidateKey || queryKey[0]);
  };
  const onError = (error: NinjaValidationError) => {
    // toast error message if _toast is provided
    // backend: ValidationError({_toast: message})
    if (error.errors._toast) toast.error(error.errors._toast);
  };

  const _catch = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      // It's an AxiosError, so handle it accordingly
      const data: { detail?: NinjaValidationData } = error.response?.data || {};
      throw new NinjaValidationError(error.message, data?.detail || {});
    } else if (error instanceof Error)
      throw new NinjaValidationError(error.message, {});
    // Handle unexpected error shapes
    else throw new NinjaValidationError('An unknown error occurred.', {});
  };

  // query object for list of OutSchema
  const query = useQuery<OutSchema[], NinjaValidationError>(
    queryKey,
    () =>
      client
        .get<OutSchema[]>(url, { params })
        .then((res) => res.data)
        .catch(_catch),
    getQueryOptions(false)
  );

  // query object for single OutSchema
  const singleQuery = useQuery<OutSchema, NinjaValidationError>(
    queryKey,
    () => client.get<OutSchema>(`${url}/${params.id}`).then((res) => res.data),
    getQueryOptions(true)
  );

  const _methodAllowedValidation = (method: string) => {
    if (!allowedMethods.includes(method)) throw new Error('Method not allowed');
  };

  // POST, PUT, DELETE
  const create = useMutation({
    mutationFn: async (data: Partial<InSchema>) => {
      _methodAllowedValidation('POST');
      return client
        .post(url, data)
        .then((res) => res.data)
        .catch(_catch);
    },
    onSuccess,
    onError,
  });
  const update = useMutation({
    mutationFn: async (data: Partial<OutSchema>) => {
      _methodAllowedValidation('PUT');
      return client
        .put(`${url}/${data.id}`, data)
        .then((res) => res.data)
        .catch(_catch);
    },
    onSuccess,
    onError,
  });
  const _delete = useMutation({
    mutationFn: async (id: number | UUID) => {
      _methodAllowedValidation('DELETE');
      return client.delete(`${url}/${id}`).catch(_catch);
    },
    onSuccess,
    onError,
  });

  // list of OutSchema (initially empty)
  const _data = query.data || [];

  // map of OutSchema where key is id and value is OutSchema
  const _dataMap = !isSingleResourceRequest
    ? _data.reduce((acc: Record<number | UUID, OutSchema>, item) => {
        acc[item.id] = item;
        return acc;
      }, {})
    : {};

  // OutSchema, singleQuery.data or undefined
  const _instance: OutSchema | undefined = singleQuery.data;

  // default query object where query.data is undefined (till request success) or list or single of OutSchema
  const _query = isSingleResourceRequest ? singleQuery : query;

  const _errors = mergeErrors(
    query.error?.errors,
    singleQuery.error?.errors,
    create.error?.errors,
    update.error?.errors,
    _delete.error?.errors
  );

  return {
    ..._query, // default query object where query.data is undefined (till request success) or list of OutSchema
    _data, // list of OutSchema (initially empty)
    _dataMap, // map of OutSchema where key is id and value is OutSchema
    _instance, // OutSchema, first element of query.data or empty object
    create, // create mutation object
    update, // update mutation object
    _delete, // delete mutation object
    _errors,
  };
};

export const useElementsPagination = (
  initialPage = 1,
  initialOption = defaultElementsPerPageOptions[0]
) => {
  const [elementsPerPage, setElementsPerPage] = React.useState(initialOption);
  const [page, setPage] = React.useState(initialPage);
  return { elementsPerPage, setElementsPerPage, page, setPage };
};

export const useImage = (organizationId: number, imageId: string) => {
  const url = `/web/organization-images/${imageId}`;
  const query = useQuery(
    ['organization-images', { organizationId, imageId }],
    () => client.get<UploadedFileSchema>(url).then((response) => response.data),
    { enabled: !!imageId }
  );
  return {
    image: query.data || null,
    ...query,
  };
};
