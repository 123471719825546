import React from 'react';
import {
  useEsgStakeholderAnswerComments,
  useEsgStakeholderAnswerCommentsReadOnly,
} from '../../hooks';
import CommentsPopup from 'src/components/CommentsPopup/CommentsPopup';
import { EsgStakeholderAnswerCommentsOutSchema } from '../../types';

interface EsgStakeholderAnswerCommentsPopupProps {
  esgStakeholderAnswerId: number;
  readOnly?: boolean;
}

function EditableCommentsPopup({
  esgStakeholderAnswerId,
}: {
  esgStakeholderAnswerId: number;
}) {
  const { _data, _delete, create, update } = useEsgStakeholderAnswerComments();

  const comments = _data.filter(
    (x) => x.esg_stakeholder_answer_id === esgStakeholderAnswerId
  );

  const handleCreate = React.useCallback(
    async (text: string) => {
      await create.mutateAsync({
        esg_stakeholder_answer_id: esgStakeholderAnswerId,
        text,
      });
    },
    [create, esgStakeholderAnswerId]
  );

  const handleUpdate = React.useCallback(
    async (updated: EsgStakeholderAnswerCommentsOutSchema) => {
      await update.mutateAsync(updated);
    },
    [update]
  );

  const handleDelete = React.useCallback(
    async (id: number) => {
      await _delete.mutateAsync(id);
    },
    [_delete]
  );

  return (
    <CommentsPopup
      comments={comments}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      badgeColor="primary"
    />
  );
}

function ReadOnlyCommentsPopup({
  esgStakeholderAnswerId,
}: {
  esgStakeholderAnswerId: number;
}) {
  const { _data: comments } = useEsgStakeholderAnswerCommentsReadOnly({
    esg_stakeholder_answer_id: esgStakeholderAnswerId,
  });

  return <CommentsPopup comments={comments} badgeColor="primary" readOnly />;
}

export default function EsgStakeholderAnswerCommentsPopup(
  props: EsgStakeholderAnswerCommentsPopupProps
) {
  const { esgStakeholderAnswerId, readOnly = false } = props;

  return readOnly ? (
    <ReadOnlyCommentsPopup esgStakeholderAnswerId={esgStakeholderAnswerId} />
  ) : (
    <EditableCommentsPopup esgStakeholderAnswerId={esgStakeholderAnswerId} />
  );
}
