import React from 'react';
import { StyledFlex } from 'src/components/StyledFlex';
import { FieldAnswerOutSchema } from '../types';
import { Button } from '@mui/material';

interface Props {
  fieldAnswer?: FieldAnswerOutSchema;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  onSaveClick: () => void;
}

export const buttonIdPrefix = 'field-save-button-qa';

export const makeButtonId = (fieldAnswerId: number) =>
  `${buttonIdPrefix}:${fieldAnswerId}`;

export default function Buttons(props: Readonly<Props>) {
  const { fieldAnswer, onCancelClick, onDeleteClick, onSaveClick } = props;
  return (
    <StyledFlex mt="10px">
      {!!fieldAnswer && (
        <Button size="small" variant="outlined" onClick={onDeleteClick}>
          delete
        </Button>
      )}
      <Button size="small" variant="outlined" onClick={onCancelClick}>
        cancel
      </Button>
      <Button
        id={fieldAnswer && makeButtonId(fieldAnswer.id)}
        size="small"
        variant="outlined"
        onClick={onSaveClick}
      >
        save
      </Button>
    </StyledFlex>
  );
}
