import { UUID } from 'crypto';
import React from 'react';
import { useFields, useQuestionAnswers, useQv3Context } from '../hooks';
import { Box, Button, Typography } from '@mui/material';
import Field from './Field';
import { Delete } from '@mui/icons-material';
import { buttonIdPrefix } from './Buttons';

interface Props {
  questionId: UUID;
  questionAnswerId: number;
}

export default function Fields(props: Readonly<Props>) {
  const { questionId, questionAnswerId } = props;
  const { modifiedQuestionAnswers } = useQv3Context();
  const questionAnswers = useQuestionAnswers({}, { enabled: false });

  const { _data: fields, isLoading } = useFields(
    { question_id: questionId },
    { enabled: !!questionId }
  );

  if (isLoading) return null;
  if (!questionId) return null;

  return (
    <Box>
      <Typography
        sx={{
          width: '100%',
          textAlign: 'right',
        }}
      >
        Answer: #{questionAnswerId}
      </Typography>

      {fields.map((field) => (
        <Field
          key={`${field.id}::${questionAnswerId}`}
          questionAnswerId={questionAnswerId}
          field={field}
        />
      ))}
      {modifiedQuestionAnswers.ids.includes(questionAnswerId) && (
        <Button
          startIcon={<Delete />}
          fullWidth
          variant="outlined"
          onClick={() =>
            document
              .querySelectorAll(`button[id^="${buttonIdPrefix}"]`)
              .forEach((button) => (button as HTMLButtonElement).click())
          }
        >
          Save
        </Button>
      )}
      <Button
        sx={{ mt: '20px' }}
        startIcon={<Delete />}
        fullWidth
        variant="outlined"
        color="error"
        onClick={() => questionAnswers._delete.mutate(questionAnswerId)}
      >
        Delete answer
      </Button>
    </Box>
  );
}
