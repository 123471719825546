import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Typography,
  colors,
} from '@mui/material';
import { InputProps } from 'src/QuestionnairesV3/types';

export default function ToggleButtonGroup(props: Readonly<InputProps>) {
  const { t } = useTranslation();
  const { error, value, field, onChange } = props;

  const booleanOptions = [
    {
      id: `${field.id}-true`,
      key: true,
      label: t('questionnaireV3.field.yes'),
    },
    {
      id: `${field.id}-false`,
      key: false,
      label: t('questionnaireV3.field.no'),
    },
  ];

  const options = field.type === 'boolean' ? booleanOptions : [];

  return (
    <>
      <MuiToggleButtonGroup
        fullWidth
        exclusive
        value={value}
        onChange={(event, newValue) => onChange?.(newValue, event)}
        // disabled={disabled}
      >
        {options?.map((option) => (
          <ToggleButton
            key={option.id}
            value={option.key}
            // disabled={disabled}
            className={error && 'Mui-error'}
          >
            {option.label}
          </ToggleButton>
        ))}
      </MuiToggleButtonGroup>
      {error && <Typography variant="errorHelperText">{error}</Typography>}
    </>
  );
}
