import React from 'react';
import DOMPurify from 'dompurify';

import { Box, Typography } from '@mui/material';

import { MAIN_COLOR } from '../utils';
import Section from '../components/Section';

interface Props {
  description?: string;
}

export default function Description(props: Readonly<Props>) {
  const { description } = props;
  if (!description) return null;
  return (
    <Box>
      <Section section="intro" />
      <Typography
        variant="h5"
        sx={{
          '& > p': {
            borderRadius: ' 0 16px 16px 16px',
            bgcolor: MAIN_COLOR,
            p: '24px',
          },
        }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            description.replace(/<p>&nbsp;<\/p>/g, '')
          ),
        }}
      />
    </Box>
  );
}
