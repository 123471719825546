import React, { ChangeEvent, MouseEvent } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';

import { useFieldAnswers, useQv3Context } from '../hooks';
import FieldInput from './Inputs';
import { FieldOutSchema, FieldValueSchema } from '../types';
import Buttons from './Buttons';

interface Props {
  questionAnswerId: number;
  field: FieldOutSchema;
}

export default function Field(props: Readonly<Props>) {
  const { questionAnswerId, field } = props;
  const { modifiedQuestionAnswers } = useQv3Context();
  const fieldAnswers = useFieldAnswers(
    { question_answer_id: questionAnswerId, field_id: field.id },
    { enabled: !!questionAnswerId && !!field }
  );

  const fieldAnswer = fieldAnswers._data[0];
  const isNew = !fieldAnswer?.id;

  const [value, setValue] = React.useState<FieldValueSchema>(null);
  const [originalValue, setOriginalValue] =
    React.useState<FieldValueSchema>(null);
  const isModified = value !== originalValue;

  React.useEffect(() => {
    if (!fieldAnswer) return;
    setValue(fieldAnswer.value);
    setOriginalValue(fieldAnswer.value);
  }, [fieldAnswer]);

  if (!field) return null;

  const handleOnChange = (
    value: FieldValueSchema,
    _: ChangeEvent | MouseEvent | SelectChangeEvent
  ) => {
    setValue(value);
    modifiedQuestionAnswers.add(questionAnswerId);
  };

  const handleCancelClick = () => setValue(originalValue);

  const handleDeleteClick = () =>
    fieldAnswers._delete.mutateAsync(fieldAnswer.id).then(() => {
      setValue(null);
      setOriginalValue(null);
    });

  const handleSaveClick = () =>
    !isNew
      ? fieldAnswers.update.mutate({ id: fieldAnswer.id, value })
      : fieldAnswers.create.mutate({
          field_id: field.id,
          question_answer_id: questionAnswerId,
          value,
        });

  return (
    <Box m={0} mb="24px">
      <Typography variant="body1" fontWeight={600} color="#38414f">
        {field.title}
      </Typography>
      <br />
      <FieldInput
        field={field}
        value={value}
        onChange={handleOnChange}
        error={fieldAnswers._errors[field.id]}
      />
      {isModified && (
        <Buttons
          fieldAnswer={fieldAnswer}
          onCancelClick={handleCancelClick}
          onDeleteClick={handleDeleteClick}
          onSaveClick={handleSaveClick}
        />
      )}
    </Box>
  );
}
