import React, { useState } from 'react';
import { useLanguage } from 'src/common/hooks';
import {
  useEsgSubTopic,
  useEsgStakeholderAnswer,
  useEsgTopic,
  useEsgSubSubTopic,
  useEsgStakeholderAnswerComments,
} from 'src/Esg/Dmav2/hooks';
import CustomSelect from 'src/components/CustomSelect';

import { Divider, Typography, IconButton, Collapse, Box } from '@mui/material';
import {
  StyledCell,
  StyledCellSm,
  StyledRow,
  StyledSubTopicCell,
} from './styles';
import { ExpandMore, ExpandLess, HelpOutline } from '@mui/icons-material';
import {
  EsgStakeholderSurveyTopicsSchema,
  EsgStakeholderAnswerInSchema,
  EsgStakeholderAnswerOutSchema,
  EsgDmaOverriddenSubSubTopicOutSchema,
} from '../../types';
import { decodeTokenFromParams } from '../utils';
import LightTooltipHtml from 'src/components/LightTooltipHtml';
import EsgStakeholderAnswerCommentsPopup from './EsgStakeholderAnswerCommentsPopup';

interface QuestionnaireTableProps {
  esgStakeholderSurveyId?: number;
  readOnly?: boolean;
  topics?: EsgStakeholderSurveyTopicsSchema;
}

export default function QuestionnaireTable(props: QuestionnaireTableProps) {
  const { t } = useLanguage();
  const { data: esgTopics } = useEsgTopic();

  const token_data = decodeTokenFromParams();

  const surveyParams = props.esgStakeholderSurveyId && {
    esg_stakeholder_survey_id: props.esgStakeholderSurveyId,
  };
  const {
    _data: answers,
    update,
    create,
    _delete: deleteAnswer,
  } = useEsgStakeholderAnswer(surveyParams || {});

  const [collapsedSubtopics, setCollapsedSubtopics] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleSubtopic = (subtopicId: number) => {
    setCollapsedSubtopics((prev) => ({
      ...prev,
      [subtopicId]: !prev[subtopicId],
    }));
  };

  const changeAnswer = (
    data: EsgStakeholderAnswerInSchema | EsgStakeholderAnswerOutSchema
  ) => {
    if (props.readOnly) return;

    const payload:
      | EsgStakeholderAnswerInSchema
      | EsgStakeholderAnswerOutSchema = {
      ...data,
      esg_dma_sub_topic_id: data.esg_dma_sub_sub_topic_id
        ? null
        : data.esg_dma_sub_topic_id,
      esg_dma_sub_sub_topic_id: data.esg_dma_sub_sub_topic_id || null,
    };

    if ('id' in payload && payload.id) {
      if (payload.positive === null && payload.negative === null) {
        deleteAnswer.mutate(payload.id);
      } else {
        update.mutate(payload);
      }
    } else {
      create.mutate(payload);
    }
  };

  const answerMap = new Map(
    answers.map((answer) => [
      answer.esg_dma_sub_topic_id || answer.esg_dma_sub_sub_topic_id,
      answer,
    ])
  );

  if (!esgTopics) {
    return <div>Loading...</div>;
  }

  const allSubtopics = props.topics?.sub_topics || [];
  const selectedSubSubtopics =
    props.topics?.sub_sub_topics?.filter(
      (sst) => sst.is_selected && !sst.is_virtual
    ) || [];

  //  Group sub-subtopics by subtopic_id for easy nesting
  const subSubtopicsBySubtopicId = selectedSubSubtopics.reduce<
    Record<number, EsgDmaOverriddenSubSubTopicOutSchema[]>
  >((acc, sst) => {
    if (!acc[sst.subtopic_id]) {
      acc[sst.subtopic_id] = [];
    }
    acc[sst.subtopic_id].push(sst);
    return acc;
  }, {});

  const subtopicsWithSubSub = allSubtopics.map((subtopic) => ({
    ...subtopic,
    subSubTopics: subSubtopicsBySubtopicId[subtopic.id] || [],
  }));

  const visibleSubtopics = subtopicsWithSubSub.filter(
    (st) => st.is_selected || st.subSubTopics.length > 0
  );

  //  Group the valid subtopics by their topic_id, and attach them to each topic
  const topicsWithSubTopics = esgTopics
    .map((topic) => {
      const subtopicsForThisTopic = visibleSubtopics.filter(
        (subtopic) => subtopic.topic_id === topic.id
      );
      return {
        ...topic,
        subtopics: subtopicsForThisTopic,
      };
    })
    .filter((topic) => topic.subtopics.length > 0);

  const translatedColumn = (key: string) =>
    t(`esg:dma.survey.table.columns.${key}`);
  const getImpactOptions = [
    { value: 0, label: t('esg:dma.survey.table.selectOptions.0') },
    { value: 1, label: t('esg:dma.survey.table.selectOptions.1') },
    { value: 2, label: t('esg:dma.survey.table.selectOptions.2') },
    { value: 3, label: t('esg:dma.survey.table.selectOptions.3') },
    { value: null, label: t('esg:dma.survey.table.selectOptions.null') },
  ];

  const esg_stakeholder_survey_id =
    token_data?.data?.esg_stakeholder_survey_id ??
    props.esgStakeholderSurveyId ??
    0;

  return (
    <div style={{ width: '100%' }}>
      <Divider />
      <StyledRow>
        <StyledSubTopicCell>
          <Typography variant="captionText">
            {translatedColumn('subtopic')}
          </Typography>
        </StyledSubTopicCell>
        <StyledCell>
          <Typography variant="captionText">
            {translatedColumn('positiveImpact')}
          </Typography>
        </StyledCell>
        <StyledCell>
          <Typography variant="captionText">
            {translatedColumn('negativeImpact')}
          </Typography>
        </StyledCell>
        <StyledCellSm />
      </StyledRow>

      {topicsWithSubTopics.map((topic) => (
        <React.Fragment key={topic.id}>
          {/* Topic header bar */}
          <div
            style={{
              backgroundColor: topic.color,
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '24px',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">
              {topic.key}: {topic.name}
            </Typography>
            {topic.hint && (
              <LightTooltipHtml title={topic.hint} placement="right-end">
                <HelpOutline fontSize="small" sx={{ mx: 1 }} />
              </LightTooltipHtml>
            )}
          </div>

          {/* Subtopics */}
          {topic.subtopics.map((subtopic) => {
            const answer = answerMap.get(subtopic.id) || {
              positive: null,
              negative: null,
              id: null,
              esg_dma_sub_sub_topic_id: null,
            };

            const baseRow: EsgStakeholderAnswerInSchema = {
              positive: answer.positive,
              negative: answer.negative,
              esg_dma_sub_topic_id: subtopic.id,
              esg_dma_sub_sub_topic_id: null,
              esg_stakeholder_survey_id,
            };

            let row:
              | EsgStakeholderAnswerInSchema
              | EsgStakeholderAnswerOutSchema = { ...baseRow };

            if (answer.id !== null) {
              row = { ...row, id: answer.id };
            }
            const isSubtopicSelected = subtopic.is_selected;

            return (
              <React.Fragment key={subtopic.id}>
                <Divider />

                <StyledRow>
                  <StyledSubTopicCell>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                      }}
                    >
                      {subtopic.subSubTopics.length > 0 ? (
                        <IconButton
                          size="small"
                          onClick={() => toggleSubtopic(subtopic.id)}
                        >
                          {!collapsedSubtopics[subtopic.id] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </IconButton>
                      ) : (
                        <Box sx={{ marginLeft: '34px' }} />
                      )}
                      <Typography variant="subtitle2">
                        {subtopic.description}
                      </Typography>
                      {subtopic.hint && (
                        <LightTooltipHtml
                          title={subtopic.hint}
                          placement="right-end"
                        >
                          <HelpOutline fontSize="small" sx={{ mx: 1 }} />
                        </LightTooltipHtml>
                      )}
                    </Box>
                  </StyledSubTopicCell>
                  {isSubtopicSelected && (
                    <>
                      <StyledCell>
                        <CustomSelect
                          key={`${row.esg_dma_sub_topic_id}-positive`}
                          value={row.positive}
                          options={getImpactOptions}
                          onChange={(value) =>
                            changeAnswer({
                              ...row,
                              positive: value,
                              esg_dma_sub_topic_id: subtopic.id,
                              esg_dma_sub_sub_topic_id: null,
                            })
                          }
                          disabled={props.readOnly}
                        />
                      </StyledCell>
                      <StyledCell>
                        <CustomSelect
                          key={`${row.esg_dma_sub_topic_id}-negative`}
                          value={row.negative}
                          options={getImpactOptions}
                          onChange={(value) =>
                            changeAnswer({
                              ...row,
                              negative: value,
                              esg_dma_sub_topic_id: subtopic.id,
                              esg_dma_sub_sub_topic_id: null,
                            })
                          }
                          disabled={props.readOnly}
                        />
                      </StyledCell>
                      <StyledCellSm>
                        {answer.id && (
                          <EsgStakeholderAnswerCommentsPopup
                            esgStakeholderAnswerId={answer.id}
                            readOnly={props.readOnly}
                          />
                        )}
                      </StyledCellSm>
                    </>
                  )}
                </StyledRow>

                {/* Sub-Subtopics */}
                <Collapse in={!collapsedSubtopics[subtopic.id]} timeout="auto">
                  {subtopic.subSubTopics.map((subSubTopic) => {
                    const subSubAnswer = answerMap.get(subSubTopic.id) || {
                      positive: null,
                      negative: null,
                      id: null,
                      esg_dma_sub_topic_id: null,
                    };

                    const subSubRow: EsgStakeholderAnswerInSchema = {
                      positive: subSubAnswer.positive,
                      negative: subSubAnswer.negative,
                      esg_dma_sub_topic_id: null,
                      esg_dma_sub_sub_topic_id: subSubTopic.id,
                      esg_stakeholder_survey_id,
                    };

                    return (
                      <React.Fragment key={subSubTopic.id}>
                        <Divider />
                        <StyledRow>
                          <StyledSubTopicCell>
                            <Typography
                              variant="body2"
                              sx={{ paddingLeft: '54px' }}
                            >
                              {subSubTopic.description}
                            </Typography>
                          </StyledSubTopicCell>
                          <Divider />
                          <StyledCell>
                            <CustomSelect
                              key={`${subSubRow.esg_dma_sub_sub_topic_id}-positive`}
                              value={subSubRow.positive}
                              options={getImpactOptions}
                              onChange={(value) =>
                                changeAnswer({
                                  ...subSubRow,
                                  positive: value,
                                  esg_dma_sub_topic_id: null,
                                  esg_dma_sub_sub_topic_id: subSubTopic.id,
                                })
                              }
                              disabled={props.readOnly}
                            />
                          </StyledCell>
                          <StyledCell>
                            <CustomSelect
                              key={`${subSubRow.esg_dma_sub_sub_topic_id}-negative`}
                              value={subSubRow.negative}
                              options={getImpactOptions}
                              onChange={(value) =>
                                changeAnswer({
                                  ...subSubRow,
                                  negative: value,
                                  esg_dma_sub_topic_id: null,
                                  esg_dma_sub_sub_topic_id: subSubTopic.id,
                                })
                              }
                              disabled={props.readOnly}
                            />
                          </StyledCell>
                          <StyledCellSm>
                            {subSubAnswer.id && (
                              <EsgStakeholderAnswerCommentsPopup
                                esgStakeholderAnswerId={subSubAnswer.id}
                                readOnly={props.readOnly}
                              />
                            )}
                          </StyledCellSm>
                        </StyledRow>
                      </React.Fragment>
                    );
                  })}
                </Collapse>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
}
