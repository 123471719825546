import React from 'react';

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Add } from '@mui/icons-material';

interface Props {
  onClick: () => void;
}

export default function NewAnswerButton(props: Readonly<Props>) {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={props.onClick}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText primary="Create new answer" />
      </ListItemButton>
    </ListItem>
  );
}
