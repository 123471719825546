import { UUID } from 'crypto';

/* eslint-disable no-unused-vars */

type ReportId = number;
type QuestionnaireKey = string;
type QuestionId = UUID;

export interface IndicatorSchema {
  id: UUID;
  key: string;
  title: string;
  questions_count: number;
  color: string;
}

export interface AnsweredQuestionsSchema {
  [key: ReportId]: {
    [key: QuestionnaireKey]: QuestionId[];
  };
}

export interface IndicatorResponsibility {
  id: number;
  esg_questionnaire_id: UUID;
  app_user_id: number;
  deadline: string;
  report_id: number;
}

export enum QuestionnaireMethodology {
  ORIGINAL_QV3_2023_2024 = 'ORIGINAL_QV3_2023_2024',
  EFRAG_XBRL_202409 = 'EFRAG_XBRL_202409',
}

export interface EsgQuestionnaireSimpleSchema {
  key: string;
  title: string;
  color: string;
}

type ReviewStatus = 'approved' | 'rejected';

export interface EsgQuestionnaireAnswerReviewInSchema {
  esg_questionnaire_answer_id: UUID;
  status: ReviewStatus;
  comment: string;
}

export interface EsgQuestionnaireAnswerReviewOutSchema {
  id: UUID;
  reviewed_by: string; // full_name
  reviewed_by_id: number;
  reviewed_at: string;
  status: ReviewStatus;
  comment: string;
}

export interface EsgQuestionnaireAnswerOutSchema {
  id: UUID;
  review_id: UUID | null;
  last_updated: string;
  is_selected: boolean | null;
  completed: boolean | null;
  completed_by: string | null; // full_name
  completed_at: string | null;
  last_modified_at: string | null;
}
