import React from 'react';
import { TableRow } from '@mui/material';

import {
  EsgDmaSubSubTopicOutSchema,
  EsgDmaSubTopicHierarchySchema,
} from '../../../types';
import SubTopicRow from './SubTopicRow';
import SubSubTopicRow from './SubSubTopicRow';
import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import { AddCustomSubtopicRow } from '../../../components/AddCustomSubtopicRow';
import { UUID } from 'crypto';

interface Props {
  title: string;
  dmaId: UUID;
  data: {
    color?: string;
    topicId: number;
    subtopics: EsgDmaSubTopicHierarchySchema[];
    subsubtopics: EsgDmaSubSubTopicOutSchema[];
  };
}

export default function TitleRow(props: Props) {
  const { title, data } = props;
  const [collapsed, setCollapsed] = React.useState(false);
  return (
    <>
      <TableRow>
        <CollapsableButtonCell
          style={{ padding: '8px' }}
          colSpan={11}
          text={title}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          sx={{ bgcolor: data.color || '' }}
        />
      </TableRow>

      {!collapsed &&
        data.subtopics.map((subtopic) => (
          <SubTopicRow key={subtopic.id} subtopic={subtopic} />
        ))}

      {!collapsed &&
        data.subsubtopics.map((subsubtopic) => (
          <SubSubTopicRow key={subsubtopic.id} subsubtopic={subsubtopic} />
        ))}
      {!collapsed && (
        <AddCustomSubtopicRow
          dmaId={props.dmaId}
          topicId={data.topicId}
          subTopicId={data.subsubtopics[0]?.subtopic_id}
        />
      )}
    </>
  );
}
