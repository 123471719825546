import * as jwt from 'src/utils/jwt';
import { ValuesSchema } from './types';

export const MAIN_COLOR = '#E9BC46';
export const TEXT_COLOR = '#38414F'; // headlines, values
export const TEXT_COLOR_SECONDARY = '#49525F'; // paragraphs, labels

export const createTokenFetcher = (
  tokenProvider: () => Promise<string>,
  initialToken?: string
) => {
  let token: string | undefined = initialToken;

  const fetchtoken = async () => {
    const payload = jwt.decodeToken(token);
    if (!payload) {
      try {
        token = await tokenProvider();
        jwt.tryUpdateTimestampSkewFromFreshToken(token);
      } catch (err) {
        console.error(err);
      }
    }
    return token;
  };
  return fetchtoken;
};

export const genRandomUUID = (prefix: string = '') =>
  `${prefix}${crypto.randomUUID()}`;

export const getStateQuestionWithAnswers = (values: ValuesSchema) => {
  const result: { [questionId: string]: string[] } = {};
  Object.keys(values).forEach((key) => {
    const [questionId, questionAnswerId, ..._] = key.split('::');
    if (!result[questionId]) result[questionId] = [];
    if (!result[questionId].includes(questionAnswerId))
      result[questionId].push(questionAnswerId);
  });
  return result;
};
