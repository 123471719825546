import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { useQuestionTable, useQuestionAnswers } from '../../hooks';
import { UUID } from 'crypto';
import NewAnswerRow from './Rows/NewAnswer';
import Answer from './Rows/Answer';
import NoData from './Rows/NoData';
import TableRowWithDimensions from './components/TableRowWithDimensions';
import { Fullscreen } from '@mui/icons-material';
import FullScreen from './components/FullScreen';

interface Props {
  questionId: UUID;
}

export default function QuestionTable(props: Readonly<Props>) {
  const { questionId } = props;
  const { _data } = useQuestionAnswers({ question_id: questionId });
  const { question, fields, dimensions } = useQuestionTable(questionId);

  const [fullScreen, setFullScreen] = React.useState(false);

  if (!question) return null;

  const tableSx = {
    minWidth: `${160 * fields.length}px`,
    tableLayout: 'fixed',
  };

  return (
    <StyledPanel m="24px 0px">
      <Button
        sx={{ float: 'right' }}
        startIcon={<Fullscreen />}
        onClick={() => setFullScreen(true)}
      >
        Full Screen
      </Button>

      <Typography variant="h5" fontWeight={700}>
        {question.title}
      </Typography>

      <Typography sx={{ m: '10px 0px' }} variant="body1">
        {question.subtitle}
      </Typography>

      <FullScreen open={fullScreen} onClose={() => setFullScreen(false)}>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table sx={tableSx}>
            <TableHead>
              <TableRowWithDimensions dimensionsCount={dimensions.length}>
                {fields.map((field) => (
                  <TableCell key={field.id} sx={{ minWidth: '120px' }}>
                    {field.title}
                  </TableCell>
                ))}
              </TableRowWithDimensions>
            </TableHead>
            <TableBody>
              {_data.length === 0 && <NoData colSpan={fields.length} />}
              {_data.map((answer) => (
                <Answer
                  key={answer.id}
                  questionId={questionId}
                  questionAnswerId={answer.id}
                />
              ))}
              <NewAnswerRow questionId={questionId} />
            </TableBody>
          </Table>
        </TableContainer>
      </FullScreen>
    </StyledPanel>
  );
}
