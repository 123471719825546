import React from 'react';

import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { useTranslation } from 'react-i18next';
import LightTooltip from 'src/components/LightTooltip';
import { StyledDialogTitle, StyledDialogSubtitle } from '../index.styles';
import HierarchySelector from './HierarchySelector';

interface Props {
  esg_stakeholder_data_id: number;
}

export default function CheckboxHierarchyModal({
  esg_stakeholder_data_id,
}: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dma.selectingStakeholders.table',
  });

  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <LightTooltip title={t('columns.reasoningTooltip')}>
        <IconButton onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      </LightTooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <IconButton
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        <StyledDialogTitle>{t('modals.matters.title')}</StyledDialogTitle>

        <StyledDialogSubtitle>
          {t('modals.matters.subtitle')}
        </StyledDialogSubtitle>

        <div style={{ padding: '16px' }}>
          <HierarchySelector
            esg_stakeholder_data_id={esg_stakeholder_data_id}
          />
        </div>
      </Dialog>
    </div>
  );
}
