import React from 'react';
import { UUID } from 'crypto';

import { TableCell, TableRow } from '@mui/material';
import {
  useQuestionAnswers,
  useQuestionTable,
} from 'src/QuestionnairesV3/hooks';
import NewAnswerButton from '../components/NewAnswerButton';

interface Props {
  questionId: UUID;
}

export default function NewAnswer(props: Readonly<Props>) {
  const { questionId } = props;
  const { fields } = useQuestionTable(questionId);
  const questionAnswers = useQuestionAnswers({ question_id: questionId });

  const handleCreateAnswer = () =>
    questionAnswers.create.mutate({ question_id: questionId });

  return (
    <TableRow>
      <TableCell colSpan={fields.length} sx={{ p: 0 }}>
        <NewAnswerButton onClick={handleCreateAnswer} />
      </TableCell>
    </TableRow>
  );
}
